import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, InputLabel, TextField, Select, MenuItem, Container, Snackbar, Alert, Modal } from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

const CategoryManagement = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantId, setRestaurantId] = useState('');
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [menuItemsMap, setMenuItemsMap] = useState({});
  const [allMenuItems, setAllMenuItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [modalSearch, setModalSearch] = useState('');
  const [categorySearch, setCategorySearch] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const fetchRestaurants = async () => {
    try {
      const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
      setRestaurants(response.data);
      if (response.data.length > 0) {
        setRestaurantId(response.data[0].id);
      }
    } catch (error) {
      showAlert('Error fetching restaurants.');
    }
  };

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/categories?restaurantId=${restaurantId}`);
      const categoriesData = response.data;
      const itemIds = categoriesData.flatMap(category => category.items);
      fetchMenuItemsByIds(itemIds);
      setCategories(categoriesData);
      setFilteredCategories(categoriesData);
    } catch (error) {
      showAlert('Error fetching categories.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMenuItemsByIds = async (itemIds) => {
    try {
      const response = await axios.post('https://backend-cheeron.au-ki.com/onboard/items-by-ids', { itemIds });
      const items = response.data;
      const itemMap = {};
      items.forEach(item => {
        itemMap[item.id] = item.name;
      });
      setMenuItemsMap(itemMap);
    } catch (error) {
      console.error('Error fetching menu items by IDs:', error);
    }
  };

  const fetchAllMenuItems = async () => {
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/items-restaurant?restaurantId=${restaurantId}`);
      setAllMenuItems(response.data);
    } catch (error) {
      showAlert('Error fetching all menu items for restaurant.');
    }
  };

  const handleUpdateCategory = async (id, title, items, active) => {
    try {
      await axios.put('https://backend-cheeron.au-ki.com/update/update-category', { id, title, items, active });
      showAlert('Category updated successfully.');
      fetchCategories();
    } catch (error) {
      showAlert('Failed to update category.');
    }
  };
  

  const handleTitleChange = (id, value) => {
    setCategories(categories.map(category => 
      category.id === id ? { ...category, title: value } : category
    ));
  };
  const handleActiveChange = (id, value) => {
    setCategories(categories.map(category =>
      category.id === id ? { ...category, active: value } : category
    ));
    
    // If the selected category is the one being updated, update it directly
    if (selectedCategory && selectedCategory.id === id) {
      setSelectedCategory(prev => ({ ...prev, active: value }));
    }
  };
  
  
  

  const handleRemoveItem = (itemId) => {
    setSelectedCategory(prev => ({
      ...prev,
      items: prev.items.filter(id => id !== itemId)
    }));
  };

  const openViewModal = (category) => {
    setSelectedCategory(category);
    setIsViewModalOpen(true);
  };

  const openAddModal = (category) => {
    setSelectedCategory(category);
    setIsAddModalOpen(true);
    fetchAllMenuItems();
  };

  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setSelectedCategory(null);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
    setSelectedCategory(null);
    setModalSearch('');
    setSelectedItems([]);
  };

  const handleModalUpdate = () => {
    if (selectedCategory) {
      const { id, title, items, active } = selectedCategory; // Ensure all values are captured
      handleUpdateCategory(id, title, items, active); // Pass all values
      closeViewModal();
    }
  };

  const handleAddItems = () => {
    if (selectedCategory && selectedItems.length) {
      const updatedItems = [...selectedCategory.items, ...selectedItems];
      
      // Include all necessary properties, including `active`
      const { id, title, active } = selectedCategory;
      handleUpdateCategory(id, title, updatedItems, active); // Ensure `active` is passed here
  
      closeAddModal();
    }
  };
  

  const toggleSelectItem = (itemId) => {
    setSelectedItems(prevSelected =>
      prevSelected.includes(itemId)
        ? prevSelected.filter(id => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (restaurantId) {
      fetchCategories();
    }
  }, [restaurantId]);

  const handleCloseAlert = () => setOpenAlert(false);

  // Filter categories based on search input
  useEffect(() => {
    setFilteredCategories(
      categories.filter(category => 
        category.title.toLowerCase().includes(categorySearch.toLowerCase())
      )
    );
  }, [categorySearch, categories]);

  const availableMenuItems = allMenuItems.filter(item => !selectedCategory?.items.includes(item.id));

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
        <FormControl sx={{ width: '40%' }}>
          <InputLabel>Restaurant</InputLabel>
          <Select 
            label="Restaurant"
            value={restaurantId} 
            onChange={(e) => setRestaurantId(e.target.value)}
            disabled={!restaurants.length}
            sx={{ height: '40px', fontSize: '14px' }}
          >
            {restaurants.map((restaurant) => (
              <MenuItem key={restaurant.id} value={restaurant.id}>
                {restaurant.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField 
          label="Search Categories" 
          variant="outlined" 
          value={categorySearch} 
          onChange={(e) => setCategorySearch(e.target.value)} 
          sx={{ width: '40%' }}
        />

        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', textAlign: 'left' }}>
            <thead>
              <tr>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Title</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Items</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Active</th>
                <th style={{ padding: '8px', border: '1px solid #ddd' }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCategories.map((category) => (
                <tr key={category.id}>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                    <input
                      type="text"
                      value={category.title}
                      onChange={(e) => handleTitleChange(category.id, e.target.value)}
                      style={{ width: '100%' }}
                    />
                  </td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                    <button onClick={() => openViewModal(category)} style={{ cursor: 'pointer' }}>
                      Remove Items
                    </button>
                    <button onClick={() => openAddModal(category)} style={{ cursor: 'pointer', marginLeft: '5px' }}>
                      Add Items
                    </button>
                  </td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                    <select
                      value={category.active}
                      onChange={(e) => handleActiveChange(category.id, e.target.value === 'true')}
                      style={{ width: '100%' }}
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </td>

                  <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                  <button
                    onClick={() => handleUpdateCategory(category.id, category.title, category.items, category.active)}
                    style={{ cursor: 'pointer' }}
                    >
                    Update
                    </button>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Box>

      {/* View Items Modal */}
      <Modal open={isViewModalOpen} onClose={closeViewModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            {selectedCategory?.title} - Items
          </Typography> 
          <TextField 
            label="Search Items in Category" 
            variant="outlined" 
            value={modalSearch} 
            onChange={(e) => setModalSearch(e.target.value)} 
            fullWidth 
            sx={{ mb: 2 }}
            />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {selectedCategory?.items
            .filter(itemId => menuItemsMap[itemId]?.toLowerCase().includes(modalSearch.toLowerCase()))
            .map(itemId => (
                <button key={itemId} style={{ display: 'flex', alignItems: 'center', padding: '5px', border: '1px solid #ddd', cursor: 'pointer', margin: '3px' }}>
                <span>{menuItemsMap[itemId]}</span>
                <span onClick={() => handleRemoveItem(itemId)}>
                    <CloseIcon fontSize="small" style={{ marginLeft: '5px' }} />
                </span>
                </button>
            ))}

          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
            <button onClick={handleModalUpdate} style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'blue', color: 'white', border: 'none' }}>
              Update
            </button>
            <button onClick={closeViewModal} style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'gray', color: 'white', border: 'none' }}>
              Close
            </button>
          </Box>
        </Box>
      </Modal>

      {/* Add Items Modal */}
      <Modal open={isAddModalOpen} onClose={closeAddModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Add Items to {selectedCategory?.title}
          </Typography>
          <TextField 
            label="Search Items" 
            variant="outlined" 
            value={modalSearch} 
            onChange={(e) => setModalSearch(e.target.value)} 
            fullWidth 
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {availableMenuItems.filter(item => item.name.toLowerCase().includes(modalSearch.toLowerCase())).map(item => (
              <button 
                key={item.id} 
                onClick={() => toggleSelectItem(item.id)} 
                style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  padding: '5px', 
                  border: '1px solid #ddd', 
                  cursor: 'pointer', 
                  margin: '3px',
                  backgroundColor: selectedItems.includes(item.id) ? 'lightblue' : 'white'
                }}
              >
                <span>{item.name}</span>
                {selectedItems.includes(item.id) && <CloseIcon fontSize="small" style={{ marginLeft: '5px' }} />}
              </button>
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 2 }}>
            <button onClick={handleAddItems} style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'blue', color: 'white', border: 'none' }}>
              Add
            </button>
            <button onClick={closeAddModal} style={{ cursor: 'pointer', padding: '8px 16px', backgroundColor: 'gray', color: 'white', border: 'none' }}>
              Cancel
            </button>
          </Box>
        </Box>
      </Modal>

      <Snackbar 
        open={openAlert} 
        autoHideDuration={3000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseAlert} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CategoryManagement;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Switch,
  CircularProgress,
  Snackbar,
  Alert,
  Tabs,
  Tab,
} from '@mui/material';
import axios from 'axios';

const RestaurantForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    address: '',
    logo: '',
    bg: '',
    time: 0,
    isgstincluded: false,
    store_status: true,
  });
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // Tabs for Add and Update functionality

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const fetchRestaurants = async () => {
    try {
      const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
      setRestaurants(response.data);
    } catch (error) {
      console.error('Error fetching restaurants:', error);
      showAlert('Error fetching restaurants');
    }
  };

  const handleRestaurantSelection = (restaurantId) => {
    const selected = restaurants.find((r) => r.id === restaurantId);
    if (selected) {
      setFormData({
        title: selected.title,
        address: selected.address,
        logo: selected.logo,
        bg: selected.bg,
        time: selected.time,
        isgstincluded: selected.isgstincluded,
        store_status: selected.store_status,
      });
      setSelectedRestaurant(restaurantId);
    }
  };

  const handleAddRestaurant = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const id = generateId(formData.title); // Generate a unique ID for the restaurant
      const response = await axios.post('https://backend-cheeron.au-ki.com/update/create-Restaurant', {
        id,
        ...formData,
      });

      if (response.status === 201) {
        showAlert('Restaurant added successfully!');
        setFormData({
          title: '',
          address: '',
          logo: '',
          bg: '',
          time: 0,
          isgstincluded: false,
          store_status: true,
        });
        fetchRestaurants(); // Refresh the restaurant list after adding a new one
      } else {
        throw new Error('Failed to add restaurant');
      }
    } catch (error) {
      console.error('Error adding restaurant:', error);
      showAlert('Failed to add restaurant.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateRestaurant = async () => {
    try {
      setIsLoading(true);
      await axios.put('https://backend-cheeron.au-ki.com/update/update-restaurant-status', {
        restaurantId: selectedRestaurant,
        ...formData,
      });
      showAlert('Restaurant updated successfully!');
    } catch (error) {
      console.error('Error updating restaurant:', error);
      showAlert('Failed to update restaurant.');
    } finally {
      setIsLoading(false);
    }
  };

  const generateId = (title) => {
    const cleanedTitle = title.replace(/[=,*&^%$#@!]/g, '');
    const words = cleanedTitle.split(' ');
    if (words.length === 1) {
      return words[0].charAt(0).toLowerCase() + words[0].slice(1);
    } else {
      return words.map((word, index) => {
        if (index === 0) {
          return word.charAt(0).toLowerCase() + word.slice(1);
        }
        return word;
      }).join('');
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  return (
    <Container maxWidth="sm">
      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} centered>
        <Tab label="Add New Restaurant" />
        <Tab label="Update Restaurant" />
      </Tabs>

      {activeTab === 0 && (
        <Box
          component="form"
          onSubmit={handleAddRestaurant}
          sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <TextField
            label="Title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            fullWidth
          />
          <TextField
            label="Address"
            value={formData.address}
            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
            fullWidth
          />
          <TextField
            label="Logo URL"
            value={formData.logo}
            onChange={(e) => setFormData({ ...formData, logo: e.target.value })}
            fullWidth
          />
          <TextField
            label="Background Image URL"
            value={formData.bg}
            onChange={(e) => setFormData({ ...formData, bg: e.target.value })}
            fullWidth
          />
          <TextField
            label="Preparation Time (minutes)"
            type="number"
            value={formData.time}
            onChange={(e) => setFormData({ ...formData, time: parseInt(e.target.value, 10) || 0 })}
            fullWidth
          />
          <Typography>Is GST Included?</Typography>
          <Switch
            checked={formData.isgstincluded}
            onChange={(e) => setFormData({ ...formData, isgstincluded: e.target.checked })}
          />
          <Typography>Is Store Open?</Typography>
          <Switch
            checked={formData.store_status}
            onChange={(e) => setFormData({ ...formData, store_status: e.target.checked })}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Add Restaurant'}
          </Button>
        </Box>
      )}

      {activeTab === 1 && (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Select Restaurant</InputLabel>
            <Select
              value={selectedRestaurant}
              onChange={(e) => handleRestaurantSelection(e.target.value)}
            >
              {restaurants.map((restaurant) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedRestaurant && (
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Title"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                fullWidth
              />
              <TextField
                label="Address"
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                fullWidth
              />
              <TextField
                label="Logo URL"
                value={formData.logo}
                onChange={(e) => setFormData({ ...formData, logo: e.target.value })}
                fullWidth
              />
              <TextField
                label="Background Image URL"
                value={formData.bg}
                onChange={(e) => setFormData({ ...formData, bg: e.target.value })}
                fullWidth
              />
              <TextField
                label="Preparation Time (minutes)"
                type="number"
                value={formData.time}
                onChange={(e) => setFormData({ ...formData, time: parseInt(e.target.value, 10) || 0 })}
                fullWidth
              />
              <Typography>Is GST Included?</Typography>
              <Switch
                checked={formData.isgstincluded}
                onChange={(e) => setFormData({ ...formData, isgstincluded: e.target.checked })}
              />
              <Typography>Is Store Open?</Typography>
              <Switch
                checked={formData.store_status}
                onChange={(e) => setFormData({ ...formData, store_status: e.target.checked })}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateRestaurant}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Update Restaurant'}
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default RestaurantForm;

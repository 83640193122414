import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Typography,
  Checkbox,
  FormGroup,FormControlLabel,FormLabel,Switch
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

const generateMenuItemId = () => `menuItem-${uuidv4().slice(0, 8)}`;
const generateVariationId = (name) => `${name.toLowerCase().replace(/\s+/g, '-')}-${uuidv4().slice(0, 4)}`;


const AddMenuItems = () => {
  const [activeTab, setActiveTab] = useState(0); // Tabs: Grid (0), Excel (1)
  const [menuItems, setMenuItems] = useState([
    { id: generateMenuItemId(), name: '', price: '', description: '', isVeg: false, image: '', active: false, isRecommend: false, variations: '', ordertype: '', preparationtime: '', discount_type: '', discount_value: '', tags: '' },
  ]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [isExcelUploading, setIsExcelUploading] = useState(false);

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
        setRestaurants(response.data);
        if (response.data.length > 0) setSelectedRestaurant(response.data[0].id); // Select first restaurant by default
      } catch (error) {
        showAlert('Error fetching restaurants');
      }
    };
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (selectedRestaurant) {
      const fetchCategories = async () => {
        try {
          const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/categories?restaurantId=${selectedRestaurant}`);
          setCategories(response.data);
        } catch (error) {
          showAlert('Error fetching categories');
        }
      };
      fetchCategories();
    }
  }, [selectedRestaurant]);

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const validateAllFields = () => {
    let hasErrors = false;
    const updatedErrors = { ...errors };
    menuItems.forEach((item, index) => {
      ['name', 'price'].forEach((field) => {
        if (!item[field]) {
          updatedErrors[`${index}-${field}`] = `${field} is required`;
          hasErrors = true;
        }
      });
    });
    setErrors(updatedErrors);
    return !hasErrors;
  };

  const saveMenuItems = async () => {
    if (!validateAllFields()) {
      showAlert('Please fill in all required fields');
      return;
    }
    const itemsWithRestaurant = menuItems.map((item) => ({
      ...item,
      restaurantId: selectedRestaurant,
    }));

    try {
      await axios.post('https://backend-cheeron.au-ki.com/update/add-menu-item', itemsWithRestaurant);
      showAlert('Menu items added successfully!');
      setMenuItems([
        { id: generateMenuItemId(), name: '', price: '', description: '', isVeg: false, image: '', active: false, isRecommend: false, variations: '', ordertype: '', preparationtime: '', discount_type: '', discount_value: '', tags: '' },
      ]);
      setErrors({});
    } catch (error) {
      showAlert('Failed to add menu items.');
    }
  };

  const handleDownloadExcel = async () => {
    try {
      const restaurantName = restaurants.find((r) => r.id === selectedRestaurant)?.title.replace(/[^a-zA-Z0-9]/g, '_') || 'restaurant';
      const filename = `${restaurantName}-menu-add.xlsx`;
      const url = `https://backend-cheeron.au-ki.com/update/download-menu-template?restaurantId=${selectedRestaurant}`;

      const response = await axios.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      showAlert('Excel template downloaded successfully!');
    } catch (error) {
      console.error('Error downloading Excel:', error);
      showAlert('Failed to download Excel template.');
    }
  };

  const handleExcelUpload = async (e) => {
    const file = e.target.files[0];
    console.log(selectedRestaurant)
    if (!file) {
      showAlert('Please select a file.');
      return;
    }

    if (!selectedRestaurant) {
      showAlert('Please select a restaurant from the dropdown.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('restaurantId', selectedRestaurant);

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      setIsExcelUploading(true);
      await axios.post('https://backend-cheeron.au-ki.com/update/upload-menu-excel', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      showAlert('Excel file uploaded and menu items added successfully!');
    } catch (error) {
      console.error('Error uploading Excel:', error);
      showAlert('Failed to upload and add menu items from Excel.');
    } finally {
      setIsExcelUploading(false);
    }
  };

  const handleFieldChange = (index, field, value) => {
    const updatedItems = [...menuItems];
    updatedItems[index][field] = value;
    setMenuItems(updatedItems);

    validateField(index, field, value);
};

const handleNestedFieldChange = (menuItemIndex, variationIndex, field, value) => {
    const updatedItems = [...menuItems];
    updatedItems[menuItemIndex].variations[variationIndex][field] = value;
    setMenuItems(updatedItems);
    validateField(menuItemIndex, field, value);
  };

const addNewRow = () => {
    setMenuItems([...menuItems, { id: generateMenuItemId(), name: '', price: '', description: '', isVeg: false, image: '', active: false, isRecommend: false, variations: '', ordertype: '', preparationtime: '', discount_type: '', discount_value: '', tags: '' }]);
};

const removeRow = (index) => {
    const updatedItems = [...menuItems];
    updatedItems.splice(index, 1);
    setMenuItems(updatedItems);
};

const validateField = (index, field, value) => {
    const updatedErrors = { ...errors };
    if ((field === 'name' || field === 'price' || field === 'categoryId') && !value) {
      updatedErrors[`${index}-${field}`] = `${field} is required`;
    } else {
      delete updatedErrors[`${index}-${field}`];
    }
    setErrors(updatedErrors);
  };


const handleImageUpload = async (index, file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
    const response = await axios.post('https://backend-cheeron.au-ki.com/update/upload-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: { folderName: 'menu_items' }
    });
    const updatedItems = [...menuItems];
    updatedItems[index].image = response.data.imageUrl;
    setMenuItems(updatedItems);
    showAlert('Image uploaded successfully!');
    } catch (error) {
    showAlert('Failed to upload image.');
    }
};

const handleOrderTypeChange = (index, option) => {
    const updatedItems = [...menuItems];
    const ordertype = updatedItems[index].ordertype;

    if (ordertype.includes(option)) {
    updatedItems[index].ordertype = ordertype.filter((type) => type !== option);
    } else {
    updatedItems[index].ordertype = [...ordertype, option];
    }

    setMenuItems(updatedItems);
};

const handleVariationToggle = (index) => {
    const updatedItems = [...menuItems];
    if (!updatedItems[index].variations.length) {
      updatedItems[index].variations = [{ id: generateVariationId('variation'), name: '', active: true, options: [] }];
    } else {
      updatedItems[index].variations = [];
    }
    setMenuItems(updatedItems);
  };

  const handleOptionChange = (menuItemIndex, variationIndex, optionField, value, optionIndex) => {
    const updatedItems = [...menuItems];
    updatedItems[menuItemIndex].variations[variationIndex].options[optionIndex][optionField] = value;
    setMenuItems(updatedItems);
  };

  const addNewOption = (menuItemIndex, variationIndex) => {
    const updatedItems = [...menuItems];
    updatedItems[menuItemIndex].variations[variationIndex].options.push({
      id: generateVariationId('option'),
      name: '',
      rank: '',
      price: '',
      active: true,
      default: false
    });
    setMenuItems(updatedItems);
  };

  const removeOption = (menuItemIndex, variationIndex, optionIndex) => {
    const updatedItems = [...menuItems];
    updatedItems[menuItemIndex].variations[variationIndex].options.splice(optionIndex, 1);
    setMenuItems(updatedItems);
  };

  useEffect(() => {
    if (selectedRestaurant) {
      const fetchCategories = async () => {
        try {
          const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/categories?restaurantId=${selectedRestaurant}`);
          setCategories(response.data);
        } catch (error) {
          showAlert('Error fetching categories');
        }
      };
      fetchCategories();
    }
  }, [selectedRestaurant]);

  return (
    <Box sx={{ mb: 2, width: '100%' }}>
      <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
        <Tab label="Grid" />
        <Tab label="Excel" />
      </Tabs>

      {activeTab === 0 && (
        <>
          <FormControl sx={{ mb: 2, width: '25%' }}>
            <InputLabel>Restaurant</InputLabel>
            <Select value={selectedRestaurant} onChange={(e) => setSelectedRestaurant(e.target.value)}>
              {restaurants.map((restaurant) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" color="primary" onClick={saveMenuItems} sx={{ mb: 2, ml: 2 }}>
            Save
          </Button>
          <TableContainer component={Paper}>
  <Table stickyHeader>
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Price</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Veg</TableCell>
        <TableCell>Active</TableCell>
        <TableCell>Recommend</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Image URL</TableCell>
        <TableCell>Variations</TableCell>
        <TableCell>Order Type</TableCell>
        <TableCell>Preparation Time</TableCell>
        <TableCell>Discount Type</TableCell>
        <TableCell>Discount Value</TableCell>
        <TableCell>Tags (JSON)</TableCell>
        <TableCell align="center" sx={{ position: 'sticky', right: 0, background: '#fff' }}>Remove</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {menuItems.map((item, index) => (
        <React.Fragment key={item.id}>
          <TableRow sx={{ '& .MuiTableCell-root': { padding: '4px 8px' } }}>
            <TableCell sx={{ minWidth: '200px' }}>
              <TextField
                value={item.name}
                onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                fullWidth
                sx={{ mb: 1, '& .MuiInputBase-root': { height: 30 } }}
                required
                error={!!errors[`${index}-name`]}
                helperText={errors[`${index}-name`] || ''}
              />
            </TableCell>
            <TableCell sx={{ minWidth: '170px' }}>
              <TextField
                value={item.price}
                onChange={(e) => handleFieldChange(index, 'price', e.target.value)}
                type="number"
                fullWidth
                sx={{ mb: 1, '& .MuiInputBase-root': { height: 30 } }}
                required
                error={!!errors[`${index}-price`]}
                helperText={errors[`${index}-price`] || ''}
              />
            </TableCell>
            <TableCell sx={{ minWidth: '200px' }}>
              <TextField
                value={item.description}
                onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
                fullWidth
                sx={{ mb: 1, '& .MuiInputBase-root': { height: 30 } }}
              />
            </TableCell>
            <TableCell>
              <Select
                value={item.isVeg.toString()}
                onChange={(e) => handleFieldChange(index, 'isVeg', e.target.value === 'true')}
                fullWidth
                sx={{ fontSize: '13px', padding: '4px 8px' }}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </TableCell>
            <TableCell>
              <Select
                value={item.active.toString()}
                onChange={(e) => handleFieldChange(index, 'active', e.target.value === 'true')}
                fullWidth
                sx={{ fontSize: '13px', padding: '4px 8px' }}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </TableCell>
            <TableCell>
              <Select
                value={item.isRecommend.toString()}
                onChange={(e) => handleFieldChange(index, 'isRecommend', e.target.value === 'true')}
                fullWidth
                sx={{ fontSize: '13px', padding: '4px 8px' }}
              >
                <MenuItem value="true">True</MenuItem>
                <MenuItem value="false">False</MenuItem>
              </Select>
            </TableCell>
            <TableCell sx={{ minWidth: '250px' }}>
              <FormControl fullWidth required error={!!errors[`${index}-categoryId`]}>
                <InputLabel>Category</InputLabel>
                <Select
                  value={item.categoryId || ''}
                  onChange={(e) => handleFieldChange(index, 'categoryId', e.target.value)}
                >
                  {categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.title}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="caption" color="error">{errors[`${index}-categoryId`] || ''}</Typography>
              </FormControl>
            </TableCell>
            <TableCell sx={{ minWidth: '200px' }}>
              <Button
                variant="contained"
                component="label"
                sx={{ fontSize: '12px', padding: '4px 8px' }}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleImageUpload(index, e.target.files[0])}
                />
              </Button>
              {item.image && (
                <a
                  onClick={() => window.open(item.image, '_blank')}
                  style={{ marginLeft: '8px', fontSize: '12px', textDecoration: 'underline' }}
                >
                  View Image
                </a>
              )}
            </TableCell>
            <TableCell>
              <Checkbox checked={!!item.variations.length} onChange={() => handleVariationToggle(index)} />
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '4px' }}>
                {['Delivery', 'Pick Up', 'Dine In', 'Online'].map((option) => (
                  <FormControlLabel
                    key={option}
                    control={
                      <Checkbox
                        checked={item.ordertype.includes(option)}
                        onChange={() => handleOrderTypeChange(index, option)}
                      />
                    }
                    label={option}
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
                  />
                ))}
              </Box>
            </TableCell>
            <TableCell sx={{ minWidth: '150px' }}>
              <TextField
                value={item.preparationtime}
                onChange={(e) => handleFieldChange(index, 'preparationtime', e.target.value)}
                fullWidth
                sx={{ mb: 1, '& .MuiInputBase-root': { height: 30 } }}
              />
            </TableCell>
            <TableCell sx={{ minWidth: '150px' }}>
              <TextField
                value={item.discount_type}
                onChange={(e) => handleFieldChange(index, 'discount_type', e.target.value)}
                fullWidth
                sx={{ mb: 1, '& .MuiInputBase-root': { height: 30 } }}
              />
            </TableCell>
            <TableCell sx={{ minWidth: '150px' }}>
              <TextField
                value={item.discount_value}
                onChange={(e) => handleFieldChange(index, 'discount_value', e.target.value)}
                type="number"
                fullWidth
                sx={{ mb: 1, '& .MuiInputBase-root': { height: 30 } }}
              />
            </TableCell>
            <TableCell sx={{ minWidth: '150px' }}>
              <TextField
                value={item.tags}
                onChange={(e) => handleFieldChange(index, 'tags', e.target.value)}
                fullWidth
                sx={{ mb: 1, '& .MuiInputBase-root': { height: 30 } }}
              />
            </TableCell>
            <TableCell align="center" sx={{ position: 'sticky', right: 0, background: '#fff' }}>
              <IconButton color="secondary" onClick={() => removeRow(index)} disabled={menuItems.length === 1}>
                <CloseIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          {item.variations.length > 0 && (
  <TableRow>
    <TableCell colSpan={15}>
      <Box sx={{ pl: 2, pt: 1, pb: 1 }}>
        {item.variations.map((variation, vIndex) => (
          <Box key={variation.id} sx={{ mb: 1, display: 'flex', flexDirection: 'column', gap: 1 , maxWidth: '40%'}}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TextField
                value={variation.name}
                label="Variation Name"
                onChange={(e) => handleNestedFieldChange(index, vIndex, 'name', e.target.value)}
                size="small"
                sx={{ flex: 1, '& .MuiInputBase-root': { height: 30, fontSize: '12px' } }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={variation.active}
                    onChange={(e) => handleNestedFieldChange(index, vIndex, 'active', e.target.checked)}
                  />
                }
                label="Active"
                sx={{ '& .MuiTypography-root': { fontSize: '12px' } }}
              />
            </Box>
            <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '12px', mt: 1 }}>
              Options
            </Typography>
            {variation.options.map((option, oIndex) => (
              <Box key={option.id} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                  value={option.name}
                  label="Name"
                  onChange={(e) => handleOptionChange(index, vIndex, 'name', e.target.value, oIndex)}
                  size="small"
                  sx={{ width: '20%', '& .MuiInputBase-root': { height: 30, fontSize: '12px' } }}
                />
                <TextField
                  value={option.rank}
                  label="Rank"
                  onChange={(e) => handleOptionChange(index, vIndex, 'rank', e.target.value, oIndex)}
                  type="number"
                  size="small"
                  sx={{ width: '15%', '& .MuiInputBase-root': { height: 30, fontSize: '12px' } }}
                />
                <TextField
                  value={option.price}
                  label="Price"
                  onChange={(e) => handleOptionChange(index, vIndex, 'price', e.target.value, oIndex)}
                  type="number"
                  size="small"
                  sx={{ width: '15%', '& .MuiInputBase-root': { height: 30, fontSize: '12px' } }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={option.active}
                      onChange={(e) => handleOptionChange(index, vIndex, 'active', e.target.checked, oIndex)}
                    />
                  }
                  label="Active"
                  sx={{ '& .MuiTypography-root': { fontSize: '12px' } }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={option.default}
                      onChange={(e) => handleOptionChange(index, vIndex, 'default', e.target.checked, oIndex)}
                    />
                  }
                  label="Default"
                  sx={{ '& .MuiTypography-root': { fontSize: '12px' } }}
                />
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => removeOption(index, vIndex, oIndex)}
                  sx={{ marginLeft: 'auto' }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
            <Button
              onClick={() => addNewOption(index, vIndex)}
              variant="outlined"
              size="small"
              sx={{
                alignSelf: 'flex-start',
                fontSize: '12px',
                padding: '4px 8px',
                marginTop: 1,
              }}
            >
              Add Option
            </Button>
          </Box>
        ))}
      </Box>
    </TableCell>
  </TableRow>
)}

        </React.Fragment>
      ))}
    </TableBody>
  </Table>
</TableContainer>


        <IconButton color="primary" onClick={addNewRow} sx={{ mt: 2 }}>
            <AddIcon />
        </IconButton>
        </>
      )}

      {activeTab === 1 && (
        <>
          <FormControl sx={{ mb: 2, width: '25%' }}>
            <InputLabel>Restaurant</InputLabel>
            <Select value={selectedRestaurant} onChange={(e) => setSelectedRestaurant(e.target.value)}>
              {restaurants.map((restaurant) => (
                <MenuItem key={restaurant.id} value={restaurant.id}>
                  {restaurant.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
            <Button variant="contained" onClick={handleDownloadExcel}>
              Download Excel Template
            </Button>
            <Button
              variant="contained"
              component="label"
              disabled={isExcelUploading}
            >
              {isExcelUploading ? 'Uploading...' : 'Upload Excel'}
              <input type="file" hidden onChange={handleExcelUpload} />
            </Button>
          </Box>
        </>
      )}

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={() => setOpenAlert(false)}>
        <Alert onClose={() => setOpenAlert(false)} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddMenuItems;

import React, { useState, useRef, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const GridComponent = ({
  restaurants,
  restaurantId,
  setRestaurantId,
  restaurantName,
  setRestaurantName,
  categories,
  selectedCategory,
  setSelectedCategory,
  menuItems,
  setMenuItems,
  isLoading,
  handleFieldChange,
  handleUpdate,
  handleDelete,
  showAlert,
  fetchMenuItems,
}) => {
  const [expandedDescriptionId, setExpandedDescriptionId] = useState(null);
  const descriptionRef = useRef(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredItems, setFilteredItems] = useState(menuItems);

  const toggleExpandDescription = (id) => {
    setExpandedDescriptionId(id === expandedDescriptionId ? null : id);
  };

  const handleClickOutside = (event) => {
    if (descriptionRef.current && !descriptionRef.current.contains(event.target)) {
      setExpandedDescriptionId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchMenuItems();
  }, [restaurantId]);

  useEffect(() => {
    // Instant filtering based on search keyword
    if (searchKeyword) {
      setFilteredItems(
        menuItems.filter((item) =>
          item.name.toLowerCase().includes(searchKeyword.toLowerCase())
        )
      );
    } else {
      setFilteredItems(menuItems);
    }
  }, [searchKeyword, menuItems]);

  const handleOrderTypeChange = (id, option) => {
    setMenuItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              ordertype: item.ordertype.includes(option)
                ? item.ordertype.filter((type) => type !== option)
                : [...item.ordertype, option],
            }
          : item
      )
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
      {/* Restaurant Dropdown */}
      <FormControl style={{ width: '35%' }}>
        <InputLabel>Restaurant</InputLabel>
        <Select
          value={restaurantId}
          onChange={(e) => {
            setRestaurantId(e.target.value);
            setRestaurantName(restaurants.find((r) => r.id === e.target.value)?.name || '');
            setSelectedCategory(''); // Reset category when changing restaurant
          }}
          disabled={isLoading || !restaurants.length}
        >
          {restaurants.map((restaurant) => (
            <MenuItem key={restaurant.id} value={restaurant.id}>
              {restaurant.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Search Field */}
      <TextField
        label="Search Menu Item"
        variant="outlined"
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        disabled={!restaurantId}
        style={{ width: '35%' }}
      />

      {/* Menu Items Table */}
      <Box sx={{ overflowX: 'auto', mt: 3, width: '100%' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              {['Name', 'Description', 'Price', 'Active', 'Veg', 'Recommended', 'Order Type', 'Time', 'Tags', 'Image', 'Actions'].map((header) => (
                <th
                  key={header}
                  style={{
                    padding: '8px',
                    border: '1px solid #ddd',
                    textAlign: 'left',
                    backgroundColor: '#f5f5f5',
                    position: header === 'Actions' ? 'sticky' : 'static',
                    right: header === 'Actions' ? 0 : 'auto',
                    background: header === 'Actions' ? '#fff' : 'inherit',
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((item) => (
              <tr key={item.id}>
                <td>
                  <input value={item.name} onChange={(e) => handleFieldChange(item.id, 'name', e.target.value)} />
                </td>
                <td onClick={() => toggleExpandDescription(item.id)}>
                  {expandedDescriptionId === item.id ? (
                    <textarea
                      ref={descriptionRef}
                      value={item.description || ''}
                      onChange={(e) => handleFieldChange(item.id, 'description', e.target.value)}
                      style={{ width: '100%', height: '100px' }}
                    />
                  ) : (
                    <input
                      value={item.description || ''}
                      onClick={() => toggleExpandDescription(item.id)}
                      style={{ width: '100%', cursor: 'pointer' }}
                      readOnly
                    />
                  )}
                </td>
                <td>
                  <input
                    value={item.price}
                    onChange={(e) => handleFieldChange(item.id, 'price', e.target.value)}
                    type="number"
                  />
                </td>
                <td>
                  <select
                    value={item.active ? 'true' : 'false'}
                    onChange={(e) => handleFieldChange(item.id, 'active', e.target.value === 'true')}
                  >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </td>
                <td>
                  <select
                    value={item.isVeg}
                    onChange={(e) => handleFieldChange(item.id, 'isVeg', e.target.value === 'true')}
                  >
                    <option value="true">Veg</option>
                    <option value="false">Non-Veg</option>
                  </select>
                </td>
                <td>
                  <select
                    value={item.isRecommend}
                    onChange={(e) => handleFieldChange(item.id, 'isRecommend', e.target.value === 'true')}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </td>
                <td>
                  <FormGroup
                    row
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr', // Two columns
                      alignItems: 'center',
                      gap: '4px',
                      padding: '8px',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      backgroundColor: '#f9f9f9'
                    }}
                  >
                    {['Delivery', 'Pick Up', 'Dine In', 'Online'].map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            size="small"
                            checked={item.ordertype.includes(option)}
                            onChange={() => handleOrderTypeChange(item.id, option)}
                            sx={{ padding: '4px' }}
                          />
                        }
                        label={option}
                        sx={{
                          fontSize: '0.7rem',
                          '& .MuiFormControlLabel-label': { fontSize: '0.7rem' }
                        }}
                      />
                    ))}
                  </FormGroup>
                </td>

                <td>
                  <input
                    value={item.preparationtime || ''}
                    onChange={(e) => handleFieldChange(item.id, 'preparationtime', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    value={item.tags ? JSON.stringify(item.tags) : ''}
                    onChange={(e) => handleFieldChange(item.id, 'tags', JSON.parse(e.target.value))}
                  />
                </td>
                <td>
                  {item.image ? (
                    <a
                      href={item.image}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                      style={{ fontSize: '12px', textDecoration: 'underline' }}
                    >
                      Download Image
                    </a>
                  ) : (
                    <span style={{ fontSize: '12px', color: '#888' }}>No image</span>
                  )}
                  <input
                    type="file"
                    onChange={(e) => handleFieldChange(item.id, 'imageFile', e.target.files[0])}
                    style={{ display: 'block', marginTop: '3px', fontSize: '12px' }}
                  />
                </td>
                <td style={{ position: 'sticky', right: 0, background: '#fff', zIndex: 1, padding:'3px' }}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <button onClick={() => handleUpdate(item)} disabled={isLoading}>Update</button>
                    <button onClick={() => handleDelete(item.id)} disabled={isLoading}>Delete</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default GridComponent;

import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, Select, MenuItem, Button, Container, CircularProgress, TextField } from '@mui/material';
import axios from 'axios';

const AvailabilityUpdate = () => {
  const [restaurants, setRestaurants] = useState([]);
  const [categories, setCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [restaurantId, setRestaurantId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [initialItems, setInitialItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');

  const fetchRestaurants = async () => {
    try {
      const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
      setRestaurants(response.data);
      if (response.data.length > 0) {
        setRestaurantId(response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching restaurants.');
    }
  };

  const fetchCategories = async (restaurantId) => {
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/categories?restaurantId=${restaurantId}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchMenuItems = async (restaurantId) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://backend-cheeron.au-ki.com/onboard/items-restaurant?restaurantId=${restaurantId}`);
      setMenuItems(response.data);
      setFilteredItems(response.data); // Initially show all items
      setInitialItems(response.data.map(item => ({ ...item })));
    } catch (error) {
      console.error('Error fetching menu items:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterMenuItems = () => {
    if (searchKeyword) {
      const searchFilteredItems = menuItems.filter(item =>
        item.name.toLowerCase().includes(searchKeyword.toLowerCase())
      );
      setFilteredItems(searchFilteredItems);
    } else if (categoryId) {
      const categoryFilteredItems = menuItems.filter(item => item.categoryId === categoryId);
      setFilteredItems(categoryFilteredItems);
    } else {
      setFilteredItems(menuItems); // Show all items if no filter
    }
  };

  const handleAvailabilityChange = (itemId, checked) => {
    setFilteredItems(filteredItems.map(item => item.id === itemId ? { ...item, active: checked } : item));
  };

  const handleSubmit = async () => {
    const updatedItems = filteredItems.map(item => ({
      id: item.id,
      active: item.active
    }));
    try {
      await axios.put('https://backend-cheeron.au-ki.com/onboard/update-availability', { items: updatedItems });
      alert('Availability updated successfully!');
      setInitialItems(filteredItems.map(item => ({ ...item })));
    } catch (error) {
      console.error('Error updating availability:', error);
    }
  };

  const handleCancel = () => {
    setFilteredItems(initialItems.map(item => ({ ...item })));
  };

  useEffect(() => {
    fetchRestaurants();
  }, []);

  useEffect(() => {
    if (restaurantId) {
      fetchCategories(restaurantId);
      fetchMenuItems(restaurantId);
    }
  }, [restaurantId]);

  useEffect(() => {
    filterMenuItems();
  }, [categoryId, searchKeyword]);

  return (
    <Container maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
        <FormControl sx={{ width: '40%'}}>
          <Select value={restaurantId} onChange={(e) => setRestaurantId(e.target.value)} displayEmpty sx={{ height: '40px', fontSize: '14px' }}>
            <MenuItem value="" disabled>Select Restaurant</MenuItem>
            {restaurants.map((restaurant) => (
              <MenuItem key={restaurant.id} value={restaurant.id}>
                {restaurant.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControl sx={{ width: '40%' }}>
          <Select value={categoryId} onChange={(e) => setCategoryId(e.target.value)} displayEmpty disabled={!restaurantId} sx={{ height: '40px', fontSize: '14px' }}>
            <MenuItem value="" disabled>Select Category</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <TextField
          label="Search Menu Items"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              filterMenuItems();
            }
          }}
          style={{ width: '40%' }}
          placeholder="Enter keyword and press Enter"
          disabled={!restaurantId}
        />

        {loading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ overflowX: 'auto', mt: 3, width: '75%' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
              <thead>
                <tr>
                  <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'left' }}>Item Name</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>Available</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item) => (
                  <tr key={item.id}>
                    <td style={{ padding: '8px', border: '1px solid #ddd' }}>{item.name}</td>
                    <td style={{ padding: '8px', border: '1px solid #ddd', textAlign: 'center' }}>
                      <input
                        type="checkbox"
                        checked={item.active}
                        onChange={(e) => handleAvailabilityChange(item.id, e.target.checked)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <button onClick={handleSubmit} style={{ cursor: 'pointer' }}>
                Update Availability
              </button>
              <button onClick={handleCancel} style={{ cursor: 'pointer' }}>
                Cancel
              </button>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AvailabilityUpdate;

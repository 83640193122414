// AddCategory.js
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Switch, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

const AddCategory = () => {
  const [categoryName, setCategoryName] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const generateId = (title, restaurantId) => {
    let id = title
      .split(' ')
      .map((word, index) => (index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
    return `${id}-${restaurantId}`;
  };

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get('https://backend-cheeron.au-ki.com/onboard/restaurants');
        setRestaurants(response.data);
      } catch (error) {
        showAlert('Error fetching restaurants.');
      }
    };
    fetchRestaurants();
  }, []);

  const showAlert = (message) => {
    setAlertMessage(message);
    setOpenAlert(true);
  };

  const handleAddCategory = async () => {
    if (!selectedRestaurant || !categoryName) {
      showAlert('Please select a restaurant and enter a category name.');
      return;
    }

    const id = generateId(categoryName, selectedRestaurant);

    try {
      const response = await axios.post('https://backend-cheeron.au-ki.com/update/add-category', {
        id,
        title: categoryName,
        active: isActive,
        restaurantId: selectedRestaurant,
      });
      showAlert(response.data.message || 'Category added successfully!');
      setCategoryName('');
      setIsActive(true);
      setSelectedRestaurant('');
    } catch (error) {
      showAlert('Failed to add category.');
    }
  };

  const handleCloseAlert = () => setOpenAlert(false);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>

      <FormControl fullWidth>
        <InputLabel>Restaurant</InputLabel>
        <Select
          value={selectedRestaurant}
          onChange={(e) => setSelectedRestaurant(e.target.value)}
          sx={{width:'30%'}}
        >
          {restaurants.map((restaurant) => (
            <MenuItem key={restaurant.id} value={restaurant.id}>
              {restaurant.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Category Name"
        variant="outlined"
        value={categoryName}
        onChange={(e) => setCategoryName(e.target.value)}
        sx={{width:'30%'}}
      />

      <Box display="flex" alignItems="center" gap={1}>
        <Typography>Status:</Typography>
        <Switch
          checked={isActive}
          onChange={(e) => setIsActive(e.target.checked)}
          color="primary"
        />
        <Typography>{isActive ? 'Active' : 'Inactive'}</Typography>
      </Box>

      <Button variant="contained" color="primary" onClick={handleAddCategory}
          sx={{width:'30%'}}
       >
        Add Category
      </Button>

      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="info" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddCategory;
